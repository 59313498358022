const colors = {
  blackGray: '#051d34',
  gray: '#cacaca',
  lightGray: '#f6f7f9',

  blue: '#1c73e8',
  lightBlue: '#65b5f6',
}

export default colors
